<template>
  <router-link :to="to" class="video-card no-link">
    <v-img class="elevation-1" v-ripple :aspect-ratio="162 / 100" :src="img">
    </v-img>
    <h5 class="mt-2 font-weight-bold">{{ title }}</h5>
  </router-link>
</template>

<script>
export default {
  props: ["title", "to", "video"],
  computed: {
    img() {
      const url = new URL(this.video);
      let code;

      switch (url.host) {
        case "www.youtube.com":
          code = url.searchParams.get("v");
          break;
        case "youtu.be":
          code = url.pathname.replace(/\/(.+)\/?.*/, "$1");
          break;
      }

      return `https://img.youtube.com/vi/${code}/0.jpg`;
    }
  }
};
</script>

<style>
.video-card {
  height: 100%;
}
.video-card article {
  overflow: hidden;
  background: #fff;
  position: relative;
}
.video-card h5 {
  white-space: normal;
}
</style>
