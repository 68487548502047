<template>
  <v-container>
    <template v-if="!title">
      <v-skeleton-loader class="mb-4" type="heading"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="image"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="image"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="image"></v-skeleton-loader>
    </template>
    <div class="event-post mb-10" v v-else>
      <h2 v-if="$vuetify.breakpoint.mdAndUp" class="mt-3 mb-2">{{ title }}</h2>
      <h3 v-else class="mt-3 mb-2">{{ title }}</h3>
      <div class="event-post-list">
        <v-img
          class="elevation-4"
          :aspect-ratio="162 / 100"
          v-for="(img, i) in imgs"
          :key="i"
          :src="img"
          @click="index = i"
          v-ripple
        ></v-img>
        <vue-gallery-slideshow
          :images="imgs"
          :index="index"
          @close="index = null"
        ></vue-gallery-slideshow>
      </div>
    </div>

    <v-btn
      v-if="canShare"
      class="elevation-3"
      color="secondary"
      fab
      fixed
      right
      bottom
      @click="share"
    >
      <v-icon>fas fa-share-alt</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import VueGallerySlideshow from "vue-gallery-slideshow";

export default {
  data: () => ({
    imgError: false,
    title: null,
    imgs: [],
    index: null
  }),
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    canShare() {
      return (
        Boolean(window.navigator.share) && this.$vuetify.breakpoint.smAndDown
      );
    }
  },
  methods: {
    share() {
      window.navigator.share({
        title: this.event.title,
        url: location.href
      });
    }
  },
  mounted() {
    this.$store.dispatch("getAllEvents").then(() => {
      const events = this.$store.state.events.filter(
        v => v.link === this.$route.params.event
      );

      if (!events.length) this.$router.push("/error404");

      this.title = events[0].title;
      this.imgs = events[0].imgs.split(",").map(img => this.files + img);
    });
  },
  components: {
    VueGallerySlideshow
  }
};
</script>

<style>
.event-post-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  justify-content: center;
}

.event-post-list > * {
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (min-width: 960.001px) and (max-width: 1264px) {
  .event-post-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 600.001px) and (max-width: 960px) {
  .event-post-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 600px) {
  .event-post-list {
    grid-template-columns: 1fr;
  }
}

.vgs__container {
  top: 60px;
}
</style>
