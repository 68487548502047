import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        // primary: "#b1040e",
        primary: "#022c46",
        secondary: "#fbfbfb"
      }
    }
  }
});
