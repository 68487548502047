<template lang="pug">
ul.pl-4.py-1.d-flex
  tooltip(v-for="(s, i) in social", :key="i", :tip="s.title", bottom)
    v-btn(
      v-if="!s.dropdown",
      :href="s.link",
      target="_blank",
      icon,
      fab,
      small
    )
      img.img-icon(:src="s.icon")
    v-menu(v-else, bottom, right)
      template(v-slot:activator="{ on, attrs }")
        v-btn(v-bind="attrs", v-on="on", icon, fab, small)
          img.img-icon(:src="s.icon")

      v-list
        v-list-item(
          v-for="(item, i) in s.dropdown",
          :key="i",
          :href="item.link",
          target="_blank"
        )
          v-list-item-title.text-caption {{ item.title }}
</template>

<script>
import Tooltip from "@/components/Tooltip";

export default {
  computed: {
    social() {
      return this.$store.state.holder.social;
    }
  },
  components: {
    Tooltip
  }
};
</script>

<style>
</style>
