<template lang="pug">
vue-flux.white.elevation-1.relative(
  v-if="$vuetify.breakpoint.smAndUp",
  :options="vfOptions",
  :images="vfImages",
  :transitions="vfTransitions",
  ref="slider",
  height="25.45vw"
)
  template(#controls)
    .d-flex.justify-space-between.mx-4
      v-btn(
        @click="() => $refs.slider.show('prev')",
        color="white",
        icon="icon"
      )
        v-icon(size="40") mdi-chevron-left
      v-btn(
        @click="() => $refs.slider.show('next')",
        color="white",
        icon="icon"
      )
        v-icon(size="40") mdi-chevron-right
</template>

<script>
import { VueFlux } from "vue-flux";

export default {
  data: () => ({
    vfOptions: {
      autoplay: true,
      aspectRatio: "4:1"
    },
    vfTransitions: ["zip"]
  }),
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    vfImages() {
      return this.$store.state.slides.map(({ img }) => this.files + img);
    }
  },
  beforeCreate() {
    this.$store.dispatch("getAllSlides");
  },
  components: {
    VueFlux
  }
};
</script>
