<template>
  <v-container class="mb-10" fluid>
    <h1 class="text-center">Conheça Seus Professores</h1>
    <div class="page-teachers-list">
      <v-card
        @click="showTeacher(teacher)"
        v-for="(teacher, i) in teachers"
        :key="i"
        outlined
      >
        <v-img :src="files + teacher.img" width="240px" height="280px"></v-img>
        <div
          class="page-teachers-item-title d-flex justify-center align-center"
        >
          <span class="px-2 py-1 text-uppercase font-weight-bold text-center">
            {{ teacher.title }}
          </span>
        </div>
      </v-card>
    </div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-img :src="files + selectedTeacher.img"></v-img>
        <v-card-title>{{ selectedTeacher.title }}</v-card-title>
        <v-card-text v-html="selectedTeacher.text"></v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    selectedTeacher: {},
    dialog: false,
  }),
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    teachers() {
      return this.$store.state.teachers.list;
    },
  },
  methods: {
    showTeacher(teacher) {
      this.selectedTeacher = teacher;
      this.dialog = true;
    },
  },
  beforeCreate() {
    this.$rest("teachers").get();
  },
  components: {},
};
</script>
<style>
.page-teachers-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, 240px);
  justify-content: center;
}
.page-teachers-item-title {
  height: 50px;
}
</style>
