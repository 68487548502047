<template>
  <v-app>
    <main-header></main-header>

    <v-content>
      <pop-up></pop-up>
      <router-view></router-view>
    </v-content>

    <main-footer></main-footer>
  </v-app>
</template>

<script>
import MainHeader from "./components/MainHeader/Index.vue";
import MainFooter from "./components/MainFooter";
import PopUp from "./components/PopUp.vue";

export default {
  components: {
    MainHeader,
    MainFooter,
    PopUp
  }
};
</script>

<style>
@import "./styles/ckeditor.css";
@import "./styles/custom-vuetify.css";
@import "./styles/custom-user.css";
@import "./styles/main.css";
</style>
