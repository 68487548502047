<template>
  <v-container fluid class="mb-10">
    <h2 v-if="$vuetify.breakpoint.mdAndUp" class="mt-3 text-center">
      {{ video.title }}
    </h2>
    <h3 v-else class="mt-3 text-center">{{ video.title }}</h3>

    <template v-if="!video">
      <v-skeleton-loader class="mb-4" type="heading"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="image"></v-skeleton-loader>
    </template>
    <template v-else>
      <iframe
        class="video-embed elevation-2"
        :src="embed"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </template>

    <v-btn
      v-if="canShare"
      class="elevation-3"
      color="secondary"
      fab
      fixed
      right
      bottom
      @click="share"
    >
      <v-icon>fas fa-share-alt</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    video: null
  }),
  computed: {
    time() {
      return new Intl.DateTimeFormat("pt-BR").format(new Date(this.video.date));
    },
    embed() {
      const url = new URL(this.video.video);
      let code;

      switch (url.host) {
        case "www.youtube.com":
          code = url.searchParams.get("v");
          break;
        case "youtu.be":
          code = url.pathname.replace(/\/(.+)\/?.*/, "$1");
          break;
      }

      return "https://www.youtube.com/embed/" + code;
    },
    canShare() {
      return (
        Boolean(window.navigator.share) && this.$vuetify.breakpoint.smAndDown
      );
    }
  },
  methods: {
    share() {
      window.navigator.share({
        title: this.video.title,
        url: location.href
      });
    }
  },
  mounted() {
    this.$store.dispatch("getAllVideos").then(() => {
      const videos = this.$store.state.videos.filter(
        v => v.link === this.$route.params.video
      );

      if (!videos.length) this.$router.push("/error404");

      this.video = videos[0];
    });
  }
};
</script>

<style>
.video-embed {
  min-height: 200px;
  width: 100%;
}

@media screen and (min-width: 600.001px) and (max-width: 960px) {
  .video-embed {
    height: 300px;
  }
}

@media screen and (min-width: 960.001px) {
  .video-embed {
    height: 500px;
  }
}
</style>
