import axios from "axios";
import { createModule } from "./module";
import { camelCaseToHyphen, validateOptions } from "./utils";

const http = axios.create();

const getters = ["getByProperty", "getById", "filterByProperty"];
const actions = ["get", "post", "upload", "put", "delete"];

const VuexRest = {
  install: (Vue, { base }) => {
    http.defaults.baseURL = base || "/";

    Vue.prototype.$rest = function(module) {
      if (typeof module !== "string")
        throw new TypeError("O parâmetro <module> deve ser uma string.");

      const store = this.$store;
      const callAction = (action, data) => {
        validateOptions(data || {});
        return store.dispatch(`${module}/${action}`, data || {});
      };

      if (!store.hasModule(module)) {
        store.registerModule(module, createModule(camelCaseToHyphen(module)));
      }

      const { item, list } = store.state[module];

      const rest = {
        item,
        list,
        getters: {}
      };

      for (let getter of getters)
        rest.getters[getter] = store.getters[`${module}/${getter}`];

      for (let action of actions)
        rest[action] = data => callAction(action, data);

      return rest;
    };
  }
};

export { http };
export default VuexRest;
