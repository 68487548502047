<template lang="pug">
  router-link.event-card.text-decoration-none(:to="to")
    v-img.elevation-4(
      :aspect-ratio="1"
      :src="img"
      :alt="title"
      v-ripple
    )
      template(v-slot:placeholder)
        div.icon.d-flex.justify-center.align-center
          v-icon.d-block(x-large) mdi-calendar
      div.title.d-flex.justify-center.align-center
        span.d-flex.justify-center.align-center.white--text.pa-1.text-center {{ title }}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    },
    img: {
      type: String,
      required: true
    }
  },
  computed: {
    time() {
      return new Intl.DateTimeFormat("pt-BR").format(new Date(this.date));
    }
  }
};
</script>

<style>
.event-card {
  height: 100%;
  min-height: 160px;
}
.event-card .event {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
  background: #fff;
  position: relative;
}
.event-card img {
  object-fit: cover;
  border-radius: 4px;
}
.event-card .title {
  height: 100%;
}
.event-card .title span {
  width: 100%;
  font-size: 17px;
  line-height: 18px;
  background-color: #00000080;
  min-height: 40px;
}

.event-card .icon {
  height: 100%;
}
.event-card h5 {
  white-space: normal;
}
</style>
