<template lang="pug">
div(v-if="articles.length")
  h4.pfd-regular.text-capitalize Mais Notícias
  v-divider.mb-2
  div
    router-link.d-flex.mb-3.text-decoration-none.grey--text.text--darken-4(
      v-for="({ img, title, to, date }, i) in articles",
      v-ripple,
      :key="i",
      :to="to"
    )
      v-img.mr-1.rounded-sm(:src="img", height="60", max-width="60")
        .d-flex.justify-center.align-center.h-100(v-if="!img")
          v-icon.white(color="grey", size="60") far fa-image
      .px-1.d-flex.justify-start.w-100.body-2.flex-column
        .font-weight-bold.text-uppercase.text-caption {{ title }}
        div
          v-icon.mb-1(small, left) mdi-clock
          span.text-caption {{ formatDate(date) }}
</template>

<script>
export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    articles() {
      return this.$store.state.articles
        .slice(0, 5)
        .filter(({ link }) => link !== this.$route.params.article)
        .map(({ img, title, link, date }) => ({
          img: img ? this.files + img : "",
          title,
          to: `/imprensa/noticias/${link}`,
          date,
        }));
    },
  },
  methods: {
    formatDate(date) {
      return new Intl.DateTimeFormat("pt-BR", { dateStyle: "long" }).format(
        new Date(date)
      );
    },
  },
  created() {
    this.$store.dispatch("getAllArticles");
  },
};
</script>

<style>
</style>
