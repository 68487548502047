<template>
  <v-container class="mb-10">
    <h1 class="text-center">Normas Regimentais</h1>
    <v-card class="mx-auto" max-width="800px">
      <v-list class="dividers">
        <template v-if="!rules.length">
          <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            type="list-item"
          ></v-skeleton-loader>
        </template>
        <template v-else>
          <v-list-item v-for="(rule, i) in rules" :key="i">
            <v-list-item-icon>
              <v-icon>mdi-file</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ rule.title }}</v-list-item-title>
            </v-list-item-content>

            <v-spacer></v-spacer>
            <v-btn color="green" icon @click="openFile(files + rule.file)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    rules() {
      return this.$store.state.rules;
    }
  },
  methods: {
    openFile(file) {
      window.open(file);
    }
  },
  beforeCreate() {
    this.$store.dispatch("getAllRules");
  }
};
</script>

<style>
.dividers > *:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
