<template lang="pug">
v-container.page-template.mb-10
  h1.text-center {{ title }}
  slot
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
