<template lang="pug">
v-container.mt-10
  grid-container.page-home-quick-access(
    :template="[, , 'repeat(auto-fit, 100px)']",
    :auto-flow="{ xs: 'row', md: 'column' }",
    gap="16px",
    justify="center"
  )
    router-link.d-flex.flex-column.no-link(
      v-for="({ link, img, title }, i) in quickAccess",
      :key="i",
      :to="!/^http/.test(link) ? link : ''",
      :href="/^http/.test(link) ? link : ''",
      :target="/^http/.test(link) ? '_blank' : ''"
    )
      img(:src="files + img", :alt="title")
      span.text-center.mt-2.black--text {{ title }}
</template>

<script>
export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    quickAccess() {
      return this.$store.state.quickAccess;
    }
  },
  beforeCreate() {
    this.$store.dispatch("getAllQuickAccess");
  }
};
</script>

<style>
.page-home-quick-access > * {
  transition: 0.1s;
}
.page-home-quick-access > *:hover {
  filter: drop-shadow(5px 5px 1px #00000050);
  transform: scale(1.1);
}
.page-home-quick-access img {
  max-height: 80px;
  object-fit: contain;
}
.page-home-quick-access span {
  line-height: 1rem;
}
</style>
