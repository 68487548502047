<template>
  <div>
    <div v-for="({ img }, i) in popUp" :key="i" class="text-center">
      <v-dialog v-model="dialog" max-width="850">
        <div class="pop-up">
          <div class="button">
            <v-btn rounded color="primary" icon text @click="dialog = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </div>
          <img :src="files + img" alt="" />
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    dialog: true
  }),
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    popUp() {
      return this.$store.state.popUp;
    }
  },
  beforeCreate() {
    this.$store.dispatch("getAllPopUp");
  }
};
</script>
<style>
.pop-up img {
  display: block;
  width: 100%;
}
.pop-up {
  position: relative;
  background-color: rgba(255, 255, 255, 0.897);
}
.button {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: end;
}
.v-dialog__content {
  height: auto !important;
  margin-top: 30px;
}
</style>
