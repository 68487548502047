<template lang="pug">
gallery-page(title="Atividade Complementar", :imgs="imgs")
  template(v-if="additionalActivitie.title")
    .title.mb-2 {{ additionalActivitie.title }}
    .ck-content(v-html="additionalActivitie.text")
</template>

<script>
import GalleryPage from "@/components/GalleryPage";

export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    additionalActivitieId() {
      return this.$route.params.additionalActivitie.replace(
        /^.*-([0-9]+)$/,
        "$1"
      );
    },
    additionalActivitie() {
      return this.$rest("additionalActivitie").item;
    },
    imgs() {
      if (!this.additionalActivitie.imgs) return [];

      return this.additionalActivitie.imgs.map(v => this.files + v);
    }
  },
  created() {
    this.$rest("additionalActivitie").get({ id: this.additionalActivitieId });
  },
  watch: {
    additionalActivitieId() {
      this.$rest("additionalActivitie").get({ id: this.additionalActivitieId });
    }
  },
  components: {
    GalleryPage
  }
};
</script>
