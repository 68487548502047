<template>
  <router-link :to="to" class="article-card">
    <v-img
      class="rounded"
      :aspect-ratio="162 / 100"
      :src="img"
      :alt="title"
      v-ripple
    >
      <template v-slot:placeholder>
        <div class="icon d-flex justify-center align-center">
          <v-icon class="d-block" x-large>mdi-newspaper</v-icon>
        </div>
      </template>
      <div class="legend">
        <time class="py-1 px-2 white--text" :datetime="date">{{ time }}</time>
        <h6 class="py-1 px-2 white--text font-weight-bold text-uppercase">
          {{ title }}
        </h6>
      </div>
    </v-img>
  </router-link>
</template>

<script>
export default {
  props: ["title", "date", "to", "img"],
  computed: {
    time() {
      return new Intl.DateTimeFormat("pt-BR").format(new Date(this.date));
    },
  },
};
</script>

<style>
.article-card article {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
  background: #fff;
}
.article-card > div {
  border-radius: 1px solid #ddd;
}
.article-card img {
  object-fit: cover;
  border-radius: 4px;
}
.article-card .icon {
  height: 100%;
}
.article-card time {
  background: #00000099;
  border-radius: 0 10px 10px 0;
}
.article-card h5 {
  white-space: normal;
}
.article-card .legend {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.article-card .v-image .v-responsive__content {
  background: linear-gradient(to top, #00000094 10%, transparent 50%);
}
</style>
