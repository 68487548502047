<template>
  <v-container class="mb-10">
    <h1 class="text-center">Eventos</h1>
    <div class="events-list">
      <template v-if="!events.length">
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
      </template>
      <template v-else>
        <event-card
          v-for="(event, i) in events"
          :key="i"
          :to="'/imprensa/eventos/' + event.link"
          :img="files + event.cover"
          :title="event.title"
        ></event-card>
      </template>
    </div>
  </v-container>
</template>

<script>
import eventCard from "../../components/EventCard";

export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    events() {
      return this.$store.state.events;
    }
  },
  beforeCreate() {
    this.$store.dispatch("getAllEvents");
  },
  components: {
    eventCard
  }
};
</script>

<style>
.events-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  justify-content: center;
}
@media screen and (min-width: 960.001px) and (max-width: 1264px) {
  .events-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 600.001px) and (max-width: 960px) {
  .events-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 600px) {
  .events-list {
    grid-template-columns: 1fr;
  }
}
</style>
