<template lang="pug">
nav.ml-auto.mr-4
  template(v-for="(route, i) in menu")
    v-btn.text-capitalize.font-weight-bold.body-1(
      v-if="!route.dropdown",
      :key="i",
      :to="route.path",
      text
    ) {{ route.name }}

    v-menu(v-else, :key="i", open-on-hover, bottom, offset-y)
      template(v-slot:activator="{ on, attrs }")
        v-btn.text-capitalize.font-weight-bold.body-1(
          v-bind="attrs",
          v-on="on",
          text
        ) {{ route.name }}
      v-list
        v-list-item(v-for="(r, i) in route.dropdown", :key="i", :to="r.path")
          v-list-item-title {{ r.name }}
</template>

<script>
import { menu } from "@/data.js";

export default {
  data: () => ({
    menu
  })
};
</script>

<style>
</style>
