<template lang="pug">
.fb-comments(
  :data-href="this.href",
  :data-numposts="this.numPosts",
  data-width="100%"
)
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
    numPosts: {
      type: Number,
      default: 5,
    },
  },
  mounted() {
    let root = document.querySelector("#fb-root");

    if (!root) {
      root = document.createElement("div");
      root.id = "fb-root";
      document.body.insertAdjacentElement("afterbegin", root);

      const script = document.createElement("script");
      script.id = "fb-script";
      script.crossorigin = "anonymous";
      script.src =
        "https://connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v8.0&appId=2633034543580480&autoLogAppEvents=1&";
      script.nonce = "cmVQNnja";
      document.body.insertAdjacentElement("beforeend", script);
    } else {
      window.FB.init({
        appId: "2633034543580480",
        xfbml: true,
        version: "v8.0",
      });
    }
  },
};
</script>

<style>
.fb-comments iframe {
  width: 100% !important;
}
</style>
