<template lang="pug">
v-app-bar.elevation-5(ref="header", app, fixed, :height="height")
  v-sheet.d-flex(tile, fixed, color="primary")
    social
    v-spacer
    spotlight
  .main-header.py-1.secondary
    router-link.white--text.h-100(to="/")
      img.logo.mx-md-4.mx-lg-10(src="/img/logo.png", contain, height="100%")
    nav-menu
</template>

<script>
import NavMenu from "./NavMenu";
import Social from "./Social";
import Spotlight from "./Spotlight";

export default {
  data: () => ({
    height: 170,
  }),
  beforeCreate() {
    window.onscroll = () => {
      const header = this.$refs.header;

      if (!header) return;

      if (window.scrollY >= 48) {
        header.$el.style.transform = `translateY(-48px)`;
      } else {
        header.$el.style.transform = `translateY(-0px)`;
      }
    };
  },
  components: {
    NavMenu,
    Social,
    Spotlight,
  },
};
</script>

<style>
.main-header {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template: "logo nav" / max-content auto;
  transition: 0.01s;
}
.main-header > :nth-child(1) {
  grid-area: logo;
}
.main-header > :nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.v-app-bar {
  z-index: 999;
}
</style>
