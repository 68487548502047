<template>
  <v-container class="mb-10">
    <h1 class="text-center">Vídeos</h1>
    <div class="videos-list mb-10">
      <template v-if="!videos.length">
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
      </template>
      <template v-else>
        <video-card
          v-for="(v, i) in videos"
          :key="i"
          :to="'/imprensa/videos/' + v.link"
          :date="v.date"
          :title="v.title"
          :video="v.video"
        ></video-card>
      </template>
    </div>
  </v-container>
</template>

<script>
import VideoCard from "../../components/VideoCard";

export default {
  computed: {
    videos() {
      return this.$store.state.videos;
    }
  },
  beforeCreate() {
    this.$store.dispatch("getAllVideos");
  },
  components: {
    VideoCard
  }
};
</script>

<style>
.videos-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  justify-content: center;
}
@media screen and (min-width: 960.001px) and (max-width: 1264px) {
  .videos-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 600.001px) and (max-width: 960px) {
  .videos-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 600px) {
  .videos-list {
    grid-template-columns: 1fr;
  }
}
</style>
