import { http } from "../index";

const createURL = (group, { id, prop, params }) => {
  let url = `/${group}`;
  params = params && new URLSearchParams(params).toString();

  if (id) url += "/" + id;
  if (prop) url += "/" + prop;
  if (params) url += "?" + params;

  return url;
};

const actions = {
  get: async ({ state, commit }, { id, params }) => {
    if (id) commit("refreshItem", id);

    const url = createURL(state.group, { id, params });

    const { data } = await http.get(url);
    if (data) {
      if (id) commit("updateItem", { id, data });
      else commit("updateList", data);
    }

    return data;
  },
  post: async ({ state, commit }, { data, params }) => {
    let url = createURL(state.group, { data, params });

    if (data && typeof data !== "object") data = { value: data };

    const res = await http.post(url, data);
    if (res.data) commit("updateList", res.data);
    return res.data;
  },
  put: async ({ state, commit }, { id, prop, data, params }) => {
    if (id && prop) {
      const data_ = state.list.find(v => v.id == id)[prop];
      if (data == data_) return;
    } else if (id) {
      const data_ = state.list.find(v => v.id == id);
      if (data == data_) return;
    }

    let url = createURL(state.group, { id, params });

    if (data instanceof FormData) {
      data = Object.fromEntries(data.entries());
    } else if (typeof data !== "object") {
      data = { value: data };
    }

    await http.put(url, data);
    if (id && prop) {
      commit("setProperty", {
        id,
        prop,
        data: typeof data === "object" ? data.value : data
      });
    } else if (id) {
      commit("updateItem", { id, data });
    }

    return true;
  },
  upload: async (
    { state, commit },
    { id, prop, file, params, onUploadProgress }
  ) => {
    let url = createURL(state.group, {
      id,
      prop,
      params
    });

    const config = {
      onUploadProgress
    };

    const data = new FormData();
    data.append(prop, file);

    const res = await http.post(url, data, config);

    if (!res.data.file) {
      window.console.warn(
        `VuexRest: a requisição para a rota "${url}" não teve como resposta um
        objeto json com a propiedade file especificando a url para o caminho
        do arquivo que foi feito upload.`
      );
    } else {
      if (id && prop) {
        commit("setProperty", {
          id,
          prop,
          data: res.data.file
        });
      } else if (id) {
        commit("updateItem", { id, data: res.data.file });
      }
    }

    return true;
  },
  delete: async ({ state, commit }, { id }) => {
    const url = createURL(state.group, { id });
    await http.delete(url);

    if (id) commit("remove", id);

    return true;
  }
};

export default actions;
