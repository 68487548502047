<template lang="pug">
v-container.page-template.mb-10
  h1.text-center NOTÍCIAS

  v-sheet.mx-auto
    template(v-if="!article.title")
      v-skeleton-loader.mb-4(type="paragraph")
      v-skeleton-loader.mb-4(type="header")
      v-skeleton-loader.mb-4(type="chip")
        v-skeleton-loader.mb-4(type="paragraph")
        v-skeleton-loader.mb-4(type="paragraph")
    template(v-else-if="!sm")
      v-row
        v-col(cols=12, md=8)
          .title(v-if="article.title") {{ article.title }}
          v-chip.mb-3.ml-auto
            v-icon(small, left) far fa-clock
            span {{ time }}
          div
            v-img.rounded.bordered.mb-2(
              @error="imgError = true",
              v-if="!imgError",
              :src="files + article.img"
            )
            .ck-content(v-html="article.text")
        v-col(cols=12, md=4)
          articles-widget
      facebook-comments(:href="server + $route.path")
    template(v-else)
      .title(v-if="article.title") {{ article.title }}
      v-chip.mb-3.ml-auto
        v-icon(small, left) far fa-clock
        span {{ time }}
      v-img.rounded.bordered.mb-2(
        @error="imgError = true",
        v-if="!imgError",
        :src="files + article.img"
      )
      .ck-content(v-html="article.text")
      facebook-comments.mb-4(:href="server + $route.path")
      articles-widget
  v-btn.elevation-3(
    v-if="canShare",
    color="secondary",
    fab,
    fixed,
    right,
    bottom,
    @click="share"
  )
    v-iconfas fa-share-alt
</template>

<script>
import ArticlesWidget from "@/components/ArticlesWidget";
import FacebookComments from "@/components/FacebookComments";

export default {
  data: () => ({
    imgError: false,
    article: {},
  }),
  computed: {
    server() {
      return this.$store.state.server;
    },
    files() {
      return this.$store.state.files + "/";
    },
    articleId() {
      return this.$route.params.article;
    },
    time() {
      return new Intl.DateTimeFormat("pt-BR", { dateStyle: "long" }).format(
        new Date(this.article.date)
      );
    },
    sm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    canShare() {
      return Boolean(window.navigator.share) && this.sm;
    },
  },
  methods: {
    share() {
      window.navigator.share({
        title: this.article.title,
        url: location.href,
      });
    },
  },
  mounted() {
    this.$store.dispatch("getAllArticles").then(() => {
      const articles = this.$store.state.articles.filter(
        (v) => v.link === this.articleId
      );

      if (!articles.length) this.$router.push("/error404");

      this.article = articles[0];
    });
  },
  watch: {
    articleId() {
      const articles = this.$store.state.articles.filter(
        (v) => v.link === this.articleId
      );

      if (!articles.length) this.$router.push("/error404");

      this.article = articles[0];
    },
  },
  components: {
    ArticlesWidget,
    FacebookComments,
  },
};
</script>

<style>
.article-post {
  min-height: 480px;
}
.article-post > time {
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.article-post > img {
  border-radius: 4px;
  max-height: 400px;
  max-width: 100%;
  max-width: min(600px, 100%);
}

@media screen and (max-width: 960px) {
  .article-post > img {
    margin: 0 auto;
    margin-bottom: 10px;
    display: block;
  }
}
@media screen and (min-width: 960.001px) {
  .article-post > img {
    margin-right: 16px;
    margin-bottom: 16px;
    float: left;
  }
}
.article-post > div {
  grid-area: text;
  word-break: break-word;
}
.article .article-list {
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
}
</style>
