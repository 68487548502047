<template>
  <v-container class="mb-10">
    <h1 class="text-center">Notícias</h1>
    <div class="articles-list">
      <template v-if="!articles.length">
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
        <v-skeleton-loader class="mb-4" type="card"></v-skeleton-loader>
      </template>
      <template v-else>
        <article-card
          v-for="(article, i) in articles"
          :key="i"
          :to="'/imprensa/noticias/' + article.link"
          :date="article.date"
          :img="files + article.img"
          :title="article.title"
        ></article-card>
      </template>
    </div>
  </v-container>
</template>

<script>
import ArticleCard from "../../components/ArticleCard";

export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    articles() {
      return this.$store.state.articles;
    },
  },
  beforeCreate() {
    this.$store.dispatch("getAllArticles");
  },
  components: {
    ArticleCard,
  },
};
</script>

<style>
.articles-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  justify-content: center;
}
@media screen and (min-width: 960.001px) and (max-width: 1264px) {
  .articles-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 600.001px) and (max-width: 960px) {
  .articles-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 600px) {
  .articles-list {
    grid-template-columns: 1fr;
  }
}
</style>
