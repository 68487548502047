import Vue from "vue";
import Vuex from "vuex";
import RPC from "./rpc";

Vue.use(Vuex);

const server = "https://www.escolanovadinamica.com.br";

const holder = {
  namespaced: true,
  state: {
    name: "Escola Nova Dinâmica",
    social: [
      {
        link: "https://www.facebook.com/EscolaNovaDinamicaSorriso",
        title: "Facebook @EscolaNovaDinamicaSorriso",
        icon: "/img/facebook.svg"
      },
      {
        link: "https://www.instagram.com/escolanovadinamicasorriso/",
        title: "Instagram @escolanovadinamicasorriso",
        icon: "/img/instagram.svg"
      },
      {
        link: "tel:+556635441007",
        title: "(66) 3544-1007",
        icon: "/img/phone.svg"
      },
      {
        link: "mailto:secretaria@escolanovadinamica.com.br",
        title: "secretaria@escolanovadinamica.com.br",
        icon: "/img/email.svg"
      },
      {
        link: "https://goo.gl/maps/8CkeeqiwBokw5ZiG6",
        title: "Rua Irmã Benedita, 301 - Centro Sul, Sorriso - MT, 78890-000",
        icon: "/img/map.svg"
      },
      {
        title: "WhatsApp",
        icon: "/img/whatsapp.svg",
        dropdown: [
          {
            title: "Secretaria (66) 98416-9999",
            link: "https://wa.me/5566984169999"
          },
          {
            title: "Coordenação (66) 98418-8871",
            link: "https://wa.me/5566984188871"
          },
          {
            title: "Direção (66) 98414-9999",
            link: "https://wa.me/5566984149999"
          }
        ]
      }
    ],
    map:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d973.6191060691508!2d-55.71689612823233!3d-12.55080587474995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93a0b3aa770be9b9%3A0xa4915d1516a5d6fc!2sUnip%20-%20Interativa!5e0!3m2!1sen!2sbr!4v1598556064774!5m2!1sen!2sbr"
  }
};

export default new Vuex.Store({
  state: {
    files: server,
    server: server,
    articles: [],
    calendars: [],
    events: [],
    materials: [],
    methodologies: [],
    organizationalStructure: [],
    quickAccess: [],
    rules: [],
    slides: ["/img/banner.png"],
    tips: [],
    videos: [],
    about: {},
    canteen: {},
    gymnasium: {},
    laboratory: {},
    library: {},
    infrastructure: {},
    menu: {},
    philosophy: {},
    pool: {},
    popUp: {}
  },
  ...RPC(server, {
    getAllArticles: "articles",
    getAllCalendars: "calendars",
    getAllEvents: "events",
    getAllMethodologies: "methodologies",
    getAllMaterials: "materials",
    getAllOrganizationalStructure: "organizationalStructure",
    getAllQuickAccess: "quickAccess",
    getAllSlides: "slides",
    getAllVideos: "videos",
    getAllRules: "rules",
    getAllTips: "tips",
    getAbout: "about",
    getMenu: "menu",
    getPhilosophy: "philosophy",
    getAllPopUp: "popUp"
  }),
  modules: {
    holder
  }
});
