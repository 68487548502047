<template>
  <div class="grid-container" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    template: [Object, Array],
    gap: [Object, String],
    autoFlow: [Object, String],
    autoRows: [Object, String],
    autoColumns: [Object, String],
    justify: [Object, String],
    alignItems: [Object, String],
    alignContent: [Object, String]
  },
  computed: {
    breakpoint() {
      const breakpoint = {
        xl: "lgOnly",
        lg: "lgAndUp",
        md: "mdAndUp",
        sm: "smAndUp",
        xs: "xsOnly"
      };

      for (let bp in breakpoint)
        breakpoint[bp] = this.$vuetify.breakpoint[breakpoint[bp]];

      return breakpoint;
    },
    template_() {
      const style = {};
      const { template } = this.$props;

      if (template) {
        if (Array.isArray(template)) {
          style["grid-template-areas"] = template[0];
          style["grid-template-rows"] = template[1];
          style["grid-template-columns"] = template[2];
        } else {
          this.hasBreakpoint(template, v => {
            style["grid-template-areas"] = v[0];
            style["grid-template-rows"] = v[1];
            style["grid-template-columns"] = v[2];
          });
        }
      }

      return style;
    },
    others_() {
      const propsStyle = {
        autoRows: "auto-rows",
        autoColumns: "auto-columns",
        gap: "gap",
        autoFlow: "auto-flow",
        justify: "justify-content",
        alignItems: "align-items",
        alignContent: "align-content"
      };

      const style = {};

      const {
        autoRows,
        autoColumns,
        gap,
        autoFlow,
        justify,
        alignItems,
        alignContent
      } = this.$props;

      const props = {
        autoRows,
        autoColumns,
        gap,
        autoFlow,
        justify,
        alignItems,
        alignContent
      };

      for (let prop in props) {
        if (props[prop]) {
          if (typeof props[prop] === "string") {
            style[propsStyle[prop]] = props[prop];
          } else {
            this.hasBreakpoint(props[prop], v => (style[propsStyle[prop]] = v));
          }
        }
      }
      return style;
    },
    style() {
      return {
        ...this.template_,
        ...this.others_
      };
    }
  },
  methods: {
    hasBreakpoint(target, call) {
      for (let bp of ["xl", "lg", "md", "sm", "xs"]) {
        if (target[bp] && this.breakpoint[bp]) {
          call(target[bp]);
          break;
        }
      }
    }
  },
  mounted() {
    if (this.$el.style.gridTemplateAreas) {
      this.$el.classList.add("grid-template");
    }
  }
};
</script>

<style>
.grid-container {
  display: grid !important;
}
.grid-container.grid-template > :nth-child(1) {
  grid-area: I;
}
.grid-container.grid-template > :nth-child(2) {
  grid-area: II;
}
.grid-container.grid-template > :nth-child(3) {
  grid-area: III;
}
.grid-container.grid-template > :nth-child(4) {
  grid-area: IV;
}
.grid-container.grid-template > :nth-child(5) {
  grid-area: V;
}
.grid-container.grid-template > :nth-child(6) {
  grid-area: VI;
}
.grid-container.grid-template > :nth-child(7) {
  grid-area: VII;
}
.grid-container.grid-template > :nth-child(8) {
  grid-area: VIII;
}
.grid-container.grid-template > :nth-child(9) {
  grid-area: IX;
}
.grid-container.grid-template > :nth-child(10) {
  grid-area: X;
}
.grid-container.grid-template > :nth-child(11) {
  grid-area: XI;
}
.grid-container.grid-template > :nth-child(12) {
  grid-area: XII;
}
</style>
