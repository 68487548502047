import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Index.vue";
import About from "../views/institutional/About.vue";
import Philosophy from "../views/institutional/Philosophy.vue";
import Rules from "../views/institutional/Rules.vue";
import Rule from "../views/institutional/Rule.vue";
import Methodologies from "../views/institutional/Methodologies.vue";
import Articles from "../views/news-media/Articles.vue";
import Article from "../views/news-media/Article.vue";
import Videos from "../views/news-media/Videos.vue";
import Video from "../views/news-media/Video.vue";
import Events from "../views/news-media/Events.vue";
import Event from "../views/news-media/Event.vue";
import Tips from "../views/Tips.vue";
import Materials from "../views/Materials.vue";
import Calendars from "../views/Calendars.vue";
import Menu from "../views/Menu.vue";
import Education from "../views/Education.vue";
import Structure from "../views/Structure.vue";
import AdditionalActivitie from "../views/AdditionalActivitie.vue";
import Teachers from "../views/Teachers.vue";
import Contact from "../views/Contact.vue";
import Tour from "../views/Tour.vue";
import Error404 from "../views/Error404.vue";
// import Structure from '../views/Structure.vue'

Vue.use(VueRouter);

const name = "Escola Nova Dinâmica";

const routes = [
  {
    path: "/",
    name,
    component: Home,
    meta: {
      teste: "ok"
    }
  },
  {
    path: "/institucional/quem-somos",
    name: "Quem Somos - " + name,
    component: About
  },
  {
    path: "/institucional/nossa-filosofia",
    name: "Nossa Filosofia - " + name,
    component: Philosophy
  },
  {
    path: "/institucional/normas-regimentais",
    name: "Normas - " + name,
    component: Rules
  },
  {
    path: "/institucional/normas-regimentais/:rule",
    name: "Norma - " + name,
    component: Rule
  },
  {
    path: "/institucional/nossa-metodologia",
    name: "Nossa Metodologia - " + name,
    component: Methodologies
  },
  {
    path: "/imprensa/noticias",
    name: "Notícias - " + name,
    component: Articles
  },
  {
    path: "/imprensa/noticias/:article",
    name: "Notícia - " + name,
    component: Article
  },
  {
    path: "/imprensa/eventos",
    name: "Eventos - " + name,
    component: Events
  },
  {
    path: "/imprensa/eventos/:event",
    name: "Evento - " + name,
    component: Event
  },
  {
    path: "/calendarios",
    name: "Calendários - " + name,
    component: Calendars
  },
  {
    path: "/imprensa/videos",
    name: "Vídeos - " + name,
    component: Videos
  },
  {
    path: "/imprensa/videos/:video",
    name: "Vídeo - " + name,
    component: Video
  },
  {
    path: "/dicas-educacionais",
    name: "Dicas Educacionais - " + name,
    component: Tips
  },
  { path: "/materiais", name: "Materiais - " + name, component: Materials },
  { path: "/cardapio", name: "Cardápio - " + name, component: Menu },
  {
    path: "/ensino/conheca-seus-professores",
    name: "Professores - " + name,
    component: Teachers
  },
  {
    path: "/ensino/:education",
    name: "Ensino - " + name,
    component: Education
  },
  {
    path: "/estrutura/tour-virtual",
    name: "Tour Virtual - " + name,
    component: Tour
  },
  {
    path: "/estrutura/:structure",
    name: "Estrutura - " + name,
    component: Structure
  },
  {
    path: "/atividades-complementares/:additionalActivitie",
    name: "Estrutura - " + name,
    component: AdditionalActivitie
  },
  { path: "/fale-conosco", name: "Fale Conosco - " + name, component: Contact },
  { path: "*", name: "Error404", component: Error404 }
  // { path: '/estrutura-organizacional', name: 'Estrutura Organizacional - ' + name, component: Structure },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.afterEach(to => {
  document.title = to.name;
  window.scrollTo(0, 0);
});

export default router;
