<template lang="pug">
div
  v-app-bar(fixed, app)
    router-link.h-100.white--text(to="/")
      img.logo(src="/img/logo.png", contain, height="100%")
    v-spacer
    v-app-bar-nav-icon(color="primary", @click="drawer = !drawer")
  v-navigation-drawer(v-model="drawer", fixed, temporary)
    router-link.white--text.h-100(to="/")
      img.logo.mx-8.mt-4(src="/img/logo.png", contain)
      .d-flex.flex-column.align-center
    v-btn.ml-2.mt-2(
      :href="positivoon",
      color="primary lighten-1",
      width="200px",
      dark,
      depressed
    )
      v-img(max-height="25px", max-width="25px", src="/img/positivoon.png")
      span.text-capitalize.ml-2 Positivo On

    v-btn.ml-2.mt-2(
      :href="mhund",
      color="primary lighten-1",
      width="200px",
      dark,
      depressed
    )
      v-img(max-height="25px", max-width="25px", src="/img/student.svg")
      span.text-capitalize.ml-2 Área do aluno

    v-btn.ml-2.my-2(
      :href="mhund",
      color="primary lighten-1",
      width="200px",
      dark,
      depressed
    )
      v-img(max-height="25px", max-width="25px", src="/img/teacher.png")
      span.text-capitalize.ml-2 Área do Professor
    v-list-item-group(v-model="current")
      v-list(dense, nav)
        v-list-item(to="/", link)
          v-list-item-icon
            v-icon mdi-home
          v-list-item-content
            v-list-item-title.body-2 Início
        template(v-for="(route, i) in menu")
          v-list-item(v-if="!route.dropdown", :key="i", :to="route.path", link)
            v-list-item-icon
              v-icon {{ route.icon }}
            v-list-item-content
              v-list-item-title.body-2 {{ route.name }}
          v-list-group(v-else, :key="i", :prepend-icon="route.icon", no-action)
            template(v-slot:activator)
              v-list-item.pa-0
                v-list-item-title.body-2 {{ route.name }}
            v-list-item(
              v-for="(r, i) in route.dropdown",
              :key="i",
              :to="r.path",
              link
            )
              v-list-item-content
                v-list-item-title.body-2 {{ r.name }}
</template>

<script>
import { positivoon, mhund, menu } from "@/data.js";

export default {
  data() {
    return {
      current: this.$route.path,
      drawer: false,
      positivoon,
      mhund,
      menu
    };
  }
};
</script>

<style>
.v-navigation-drawer__content .logo {
  height: 100px;
}
</style>
