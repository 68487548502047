<template lang="pug">
article-page(title="Quem Somos")
  div(v-if="about.text", v-html="about.text")
</template>


<script>
import ArticlePage from "../../components/ArticlePage";

export default {
  computed: {
    about() {
      return this.$store.state.about;
    }
  },
  beforeCreate() {
    this.$store.dispatch("getAbout");
  },
  components: {
    ArticlePage
  }
};
</script>
