<template>
  <v-container>
    <template v-if="!rule">
      <v-skeleton-loader class="mb-4" type="heading"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
    </template>
    <div class="rule-post mb-10" v v-else>
      <h2 v-if="$vuetify.breakpoint.mdAndUp" class="mt-3 mb-2">
        {{ rule.title }}
      </h2>
      <h3 v-else class="mt-3 mb-2">{{ rule.title }}</h3>
      <div v-html="rule.text"></div>
    </div>

    <v-btn
      v-if="canShare"
      class="elevation-3"
      color="secondary"
      fab
      fixed
      right
      bottom
      @click="share"
    >
      <v-icon>fas fa-share-alt</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    rule: null
  }),
  computed: {
    canShare() {
      return (
        Boolean(window.navigator.share) && this.$vuetify.breakpoint.smAndDown
      );
    }
  },
  methods: {
    share() {
      window.navigator.share({
        title: this.rule.title,
        url: location.href
      });
    }
  },
  mounted() {
    this.$store.dispatch("getAllRules").then(() => {
      const rules = this.$store.state.rules.filter(
        v => v.link === this.$route.params.rule
      );

      if (!rules.length) this.$router.push("/error404");

      this.rule = rules[0];
    });
  }
};
</script>

<style>
.rule-post {
  min-height: 480px;
}
.rule-post > time {
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.rule-post > img {
  border-radius: 4px;
  max-height: 400px;
  max-width: 100%;
  max-width: min(600px, 100%);
  float: left;
}
.rule-post > div {
  grid-area: text;
  word-break: break-word;
}
.rule .rule-list {
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
}
</style>
