<template lang="pug">
gallery-page(title="Estrutura", :imgs="imgs")
  template(v-if="structure.title")
    .title.mb-2 {{ structure.title }}
    .ck-content(v-html="structure.text")
</template>

<script>
import GalleryPage from "@/components/GalleryPage";

export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    structureId() {
      return this.$route.params.structure.replace(/^.*-([0-9]+)$/, "$1");
    },
    structure() {
      return this.$rest("structure").item;
    },
    imgs() {
      if (!this.structure.imgs) return [];

      return this.structure.imgs.map(v => this.files + v);
    }
  },
  created() {
    this.$rest("structure").get({ id: this.structureId });
  },
  watch: {
    structureId() {
      this.$rest("structure").get({ id: this.structureId });
    }
  },
  components: {
    GalleryPage
  }
};
</script>
