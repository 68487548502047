const mhund = "https://www.mhund.com.br/Login/Index";

const positivoon = "http://positivoon.com.br/";

const menu = [
  {
    dropdown: [
      { path: "/institucional/quem-somos", name: "Quem Somos" },
      { path: "/institucional/nossa-filosofia", name: "Nossa Filosofia" },
      {
        path: "/institucional/normas-regimentais",
        name: "Normas Regimentais"
      },
      {
        path: "/institucional/nossa-metodologia",
        name: "Nossa Metodologia"
      }
    ],
    name: "Institucional",
    icon: "mdi-information"
  },
  { name: "Estrutura", icon: "mdi-view-quilt", dropdown: [] },
  { name: "Ensino", icon: "mdi-school", dropdown: [] },
  {
    dropdown: [
      { path: "/imprensa/noticias", name: "Notícias" },
      { path: "/imprensa/eventos", name: "Eventos" },
      { path: "/imprensa/videos", name: "Vídeos" }
    ],
    name: "Imprensa",
    icon: "mdi-newspaper"
  },
  {
    name: "Atividades Complementares",
    icon: "mdi-pool",
    dropdown: []
  },
  {
    path: "/dicas-educacionais",
    name: "Dicas Educacionais",
    icon: "mdi-comment-alert"
  },
  { path: "/fale-conosco", name: "Contato", icon: "mdi-comment-text" }
];

export { mhund, positivoon, menu };
