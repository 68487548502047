<template>
  <v-container class="about-page mb-10">
    <h1 class="text-center">Nossa Metodologia</h1>

    <template v-if="!methodologies.length">
      <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
    </template>
    <v-expansion-panels v-else popout>
      <v-expansion-panel v-for="(m, i) in methodologies" :key="i">
        <v-expansion-panel-header>{{ m.title }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-html="m.text"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
export default {
  computed: {
    methodologies() {
      return this.$store.state.methodologies;
    }
  },
  beforeCreate() {
    this.$store.dispatch("getAllMethodologies");
  }
};
</script>
