<template lang="pug">
template-page(:title="title")
  .page-template-text
    slot
  .gallery-page-list
    v-img.elevation-4(
      v-for="(img, i) in imgs",
      v-ripple,
      @click="index = i",
      :aspect-ratio="162 / 100",
      :key="i",
      :src="img"
    )
    vue-gallery-slideshow(
      @close="index = null",
      :images="imgs",
      :index="index"
    )
</template>

<script>
import TemplatePage from "./TemplatePage";
import VueGallerySlideshow from "vue-gallery-slideshow";

export default {
  data: () => ({
    index: null
  }),
  props: {
    title: String,
    imgs: {
      type: Array,
      default: () => []
    }
  },
  components: {
    TemplatePage,
    VueGallerySlideshow
  }
};
</script>

<style>
.pate-template-text {
  font-family: "Roboto", sans-serif !important;
}
.gallery-page-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  justify-content: center;
}
.gallery-page-list > * {
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (min-width: 960.001px) and (max-width: 1264px) {
  .gallery-page-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 600.001px) and (max-width: 960px) {
  .gallery-page-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 600px) {
  .gallery-page-list {
    grid-template-columns: 1fr;
  }
}
.vgs__container {
  top: 60px;
}
</style>
