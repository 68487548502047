<template>
  <v-container class="mb-10">
    <h1 class="text-center">Dicas Educacionais</h1>
    <v-sheet class="mx-auto" max-width="800px">
      <v-list class="dividers">
        <template v-if="!tips.length">
          <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            type="paragraph"
          ></v-skeleton-loader>
        </template>
        <template v-else>
          <v-expansion-panels inset>
            <v-expansion-panel v-for="(tip, i) in tips" :key="i">
              <a
                v-if="tip.link"
                :href="tip.link"
                class="text-decoration-none grey--text text--darken-3"
                target="_blank"
              >
                <v-expansion-panel-header>
                  <div>
                    <v-icon left>mdi-open-in-new</v-icon>{{ tip.title }}
                  </div>
                  <template #actions>
                    <div></div>
                  </template>
                </v-expansion-panel-header>
              </a>
              <template v-else>
                <v-expansion-panel-header>
                  <div>
                    <v-icon left>mdi-comment-alert</v-icon>{{ tip.title }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="tip.text"></div>
                </v-expansion-panel-content>
              </template>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-list>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    tips() {
      return this.$store.state.tips;
    },
  },
  methods: {
    openFile(file) {
      window.open(file);
    },
  },
  beforeCreate() {
    this.$store.dispatch("getAllTips");
  },
};
</script>
