<template lang="pug">
div
  slide-show
  quick-access
  articles
</template>

<script>
import SlideShow from "./SlideShow";
import QuickAccess from "./QuickAccess";
import Articles from "./Articles";

export default {
  components: {
    SlideShow,
    QuickAccess,
    Articles
  }
};
</script>
