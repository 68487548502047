<template lang="pug">
div
  desktop(v-if="$vuetify.breakpoint.mdAndUp")
  mobile(v-else)
</template>

<script>
import Desktop from "./Desktop/Index.vue";
import Mobile from "./Mobile";
import { menu } from "@/data.js";

export default {
  created() {
    this.$rest("education")
      .get()
      .then(r => {
        menu.find(({ name }) => name === "Ensino").dropdown = [
          ...r
            .map(({ link, title }) => ({
              path: "/ensino/" + link,
              name: title
            }))
            .reverse(),
          {
            path: "/ensino/conheca-seus-professores",
            name: "Conheça Seus Professores"
          }
        ];
      });
    this.$rest("structure")
      .get()
      .then(r => {
        menu.find(({ name }) => name === "Estrutura").dropdown = [
          {
            path: "/estrutura/tour-virtual",
            name: "Conheça a Escola"
          },
          ...r
            .map(({ link, title }) => ({
              path: "/estrutura/" + link,
              name: title
            }))
            .reverse()
        ];
      });
    this.$rest("additional-activitie")
      .get()
      .then(r => {
        menu.find(
          ({ name }) => name === "Atividades Complementares"
        ).dropdown = r
          .map(({ link, title }) => ({
            path: "/atividades-complementares/" + link,
            name: title
          }))
          .reverse();
      });
  },
  components: {
    Desktop,
    Mobile
  }
};
</script>

<style>
.logo {
  height: 100%;
  object-fit: contain;
  max-width: 200px;
}
.v-app-bar,
.v-toolbar,
.v-toolbar * {
  z-index: 999;
}

@media screen and (min-width: 960.001px) {
  header .v-toolbar__content {
    display: block !important;
    padding: 0 !important;
  }
  .logo {
    width: 100%;
  }
}
</style>
