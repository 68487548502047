<template lang="pug">
div
  v-bottom-navigation.elevation-0(background-color="transparent", dark)
    v-btn(:href="positivoon")
      span.white--text.body-2 Positivo On
      v-img(max-height="25px", max-width="25px", src="/img/positivoon.png")

    v-btn( target="_blank" :href="mhund")
      span.white--text.body-2 Área do aluno
      v-img(max-height="25px", max-width="25px", src="/img/student.svg?v2")

    v-btn( target="_blank" :href="mhund")
      span.white--text.body-2 Área do Professor
      v-img(max-height="25px", max-width="25px", src="/img/teacher.png?v2")
</template>

<script>
import { positivoon, mhund } from "@/data.js";

export default {
  data: () => ({
    positivoon,
    mhund
  })
};
</script>

<style></style>
