<template>
  <div class="contact mb-10">
    <v-container class="mb-10">
      <h1 class="text-center">Contato</h1>
      <div class="d-flex flex-column flex-md-row justify-center">
        <registration-form
          class="mb-4 mb-md-0"
          :action="server + '/sendEmail'"
        ></registration-form>
        <ul class="d-flex flex-column">
          <div v-for="(s, i) in social" :key="i">
            <li v-if="!s.dropdown" class="mb-1">
              <v-btn :href="s.link" target="_blank" icon fab small>
                <img class="img-icon" :src="s.icon" />
              </v-btn>
              <span class="ml-2">{{ s.title }}</span>
            </li>
            <template v-else>
              <li v-for="(item, i) in s.dropdown" :key="i" class="mb-1">
                <v-btn :href="item.link" target="_blank" icon fab small>
                  <img class="img-icon" :src="s.icon" />
                </v-btn>
                <span class="ml-2">{{ item.title }}</span>
              </li>
            </template>
          </div>
        </ul>
      </div>
    </v-container>
    <v-sheet class="mx-auto" color="transparent" max-width="873">
      <iframe
        class="map elevation-2"
        :src="map"
        width="100%"
        height="400"
        frameborder="0"
        allowfullscreen
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </v-sheet>
  </div>
</template>

<script>
import RegistrationForm from "../components/RegistrationForm";

export default {
  computed: {
    server() {
      return this.$store.state.server;
    },
    social() {
      return this.$store.state.holder.social;
    },
    map() {
      return this.$store.state.holder.map;
    }
  },
  components: {
    RegistrationForm
  }
};
</script>

<style>
.contacts ul {
  padding-left: 0 !important;
  list-style: none;
}
.map {
  margin-bottom: -6px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
</style>
