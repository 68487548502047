<template>
  <v-footer class="px-4 py-6 primary d-flex justify-space-around">
    <ul class="d-flex">
      <tooltip v-for="(s, i) in social" :key="i" :tip="s.title" bottom>
        <v-btn v-if="!s.dropdown" :href="s.link" target="_blank" icon fab small>
          <img class="img-icon" :src="s.icon" />
        </v-btn>
        <v-menu v-else bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon fab small>
              <img class="img-icon" :src="s.icon" />
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, i) in s.dropdown"
              :key="i"
              :href="item.link"
              target="_blank"
            >
              <v-list-item-title class="text-caption">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </tooltip>
    </ul>
    <span class="white--text text-center py-2 text-caption"
      >{{ name }} © 2020. Todos os direitos reservados.</span
    >
    <img src="/img/mrx.png" alt="MRX Web Sites" title="MRX Web Sites" />
  </v-footer>
</template>

<script>
import Tooltip from "./Tooltip";

export default {
  data: () => ({
    logo: "/img/favicon-white.svg",
  }),
  computed: {
    name() {
      return this.$store.state.holder.name;
    },
    social() {
      return this.$store.state.holder.social;
    },
  },
  components: {
    Tooltip,
  },
};
</script>

<style>
.img-icon {
  height: 26px;
  width: 26px;
}
</style>
