<template lang="pug">
article-page(title="Nossa Filosofia")
  div(v-if="philosophy.text", v-html="philosophy.text")
</template>

<script>
import ArticlePage from "../../components/ArticlePage";

export default {
  computed: {
    philosophy() {
      return this.$store.state.philosophy;
    }
  },
  beforeCreate() {
    this.$store.dispatch("getPhilosophy");
  },
  components: {
    ArticlePage
  }
};
</script>
