<template lang="pug">
article-page(title="Ensino")
  template(v-if="education.title")
    .title.mb-2 {{ education.title }}
    .ck-content(v-html="education.text")
</template>

<script>
import ArticlePage from "@/components/ArticlePage";

export default {
  computed: {
    educationId() {
      return this.$route.params.education.replace(/^.*-([0-9]+)$/, "$1");
    },
    education() {
      return this.$rest("education").item;
    }
  },
  created() {
    this.$rest("education").get({ id: this.educationId });
  },
  watch: {
    educationId() {
      this.$rest("education").get({ id: this.educationId });
    }
  },
  components: {
    ArticlePage
  }
};
</script>
