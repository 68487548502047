<template>
  <v-container class="mb-10">
    <h1 class="text-center">Cardápio</h1>
    <v-skeleton-loader
      v-if="!menu"
      class="mb-4"
      type="image"
    ></v-skeleton-loader>
    <v-img
      v-else
      class="d-block mx-auto elevation-1"
      :src="files + menu"
      alt="Cardápio"
      max-width="700px"
    ></v-img>
  </v-container>
</template>

<script>
export default {
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    menu() {
      return this.$store.state.menu.img;
    }
  },
  beforeCreate() {
    this.$store.dispatch("getMenu");
  }
};
</script>
