<template lang="pug">
v-container.mb-10
  h1.text-center Notícias
  grid-container(gap="16px", :template="template")
    template(v-if="!articles.length")
      v-skeleton-loader.mb-4(v-for="i in 5", :key="i", type="card")
    template(v-else)
      article-card(
        v-for="({ link, date, img, title }, i) in articles",
        :key="i",
        :to="'/imprensa/noticias/' + link",
        :date="date",
        :img="files + img",
        :title="title"
      )
  .d-flex.justify-end.mt-5
    v-btn(to="/imprensa/noticias", color="primary", text) Ver mais
</template>

<script>
import ArticleCard from "@/components/ArticleCard";

export default {
  data: () => ({
    template: {
      xs: [`"I" "II" "III" "IV" "V"`, null, "1fr"],
      sm: [
        `"I   I   I  II II II"
         "III III IV IV V  V"`,
        null,
        "repeat(6, 1fr)",
      ],
      md: [
        `"I II III"
         "I IV V"`,
        null,
        "2fr 1fr 1fr",
      ],
    },
  }),
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    articles() {
      let articles = this.$store.state.articles;
      return articles && articles.slice(0, 5);
    },
  },
  beforeCreate() {
    this.$store.dispatch("getAllArticles");
  },
  components: {
    ArticleCard,
  },
};
</script>
