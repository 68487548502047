<template lang="pug">
template-page(:title="title")
  .page-template-text.ck-content
    slot
      v-skeleton-loader.my-10.mx-2(
        type="image",
        min-width="330px",
        min-height="200px"
      )
      div
        v-skeleton-loader.mb-4(type="header")
        v-skeleton-loader.mb-4(type="chip")
        v-skeleton-loader.mb-4(type="paragraph")
        v-skeleton-loader.mb-4(type="paragraph")
</template>

<script>
import TemplatePage from "./TemplatePage";

export default {
  props: {
    title: String,
  },
  components: {
    TemplatePage,
  },
};
</script>

<style>
.pate-template-text {
  font-family: "Roboto", sans-serif !important;
}
</style>
